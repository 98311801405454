import { Heading, Link, Text, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import SocialLinks from './components/SocialLinks'
import bg from '../../images/hero.jpg'

function ContactInfo({ bgAttachment }) {
	return (
		<VStack
			id='contact'
			w={'100%'}
			spacing={10}
			h={'100vh'}
			justify={'center'}
			color={'gray.50'}
			style={{
				backgroundImage: `url(${bg})`,
				backgroundAttachment: bgAttachment,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
			}}>
			<VStack spacing={10}>
				<Heading alignSelf={'center'} fontSize={'4xl'}>
					Get in touch
				</Heading>
				<Link href={'mailto: basharothman.work@gmail.com'}>
					<Text
						as={motion.div}
						whileHover={{ scale: [1, 1.2, 1.1] }}
						whileInView={{ rotateZ: [0, -5, 5, 0] }}
						viewport={{ once: true }}
						size={'md'}
						p={4}
						border={'3px solid'}
						borderRadius={10}>
						basharothman.work@gmail.com
					</Text>
				</Link>
			</VStack>
			<SocialLinks />
		</VStack>
	)
}

export default ContactInfo
