import { AspectRatio, HStack, Tooltip } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'

function ProjectTech({ technologies }) {
	return (
		<HStack>
			{technologies.map((tech) => {
				return (
					<Tooltip key={tech.name} label={tech.name}>
						<AspectRatio
							as={motion.div}
							whileHover={{ scale: [1, 1.2, 1.2, 1] }}
							// whileInView={{ rotateZ: [0, 20, -20, 0] }}
							// viewport={{ once: true }}
							ratio={1}
							w={10}>
							{tech.icon}
						</AspectRatio>
					</Tooltip>
				)
			})}
		</HStack>
	)
}

export default ProjectTech
