import { Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'

function AboutMe() {
	return (
		<VStack id='about' mb={90} align={'self-start'}>
			<Heading mt={90} mb={50}>
				About me
			</Heading>

			<Text>
				Passionate software engineer with hands-on experience in both front-end and back-end
				technologies. With a diverse background that includes property management and aviation, and
				holding a BA in Psychology with a minor in Philosophy, I bring a unique perspective to
				problem-solving.
			</Text>
			<Text>
				Motivated by challenges and a lover of continuous learning, I’m a proud General Assembly
				bootcamp graduate. My technical skills are further enhanced by my multilingual abilities and
				strong interpersonal skills. Always eager to learn and bring diverse insights to tech
				solutions, I am excited to apply my multifaceted expertise in a collaborative team
				environment.
			</Text>
		</VStack>
	)
}

export default AboutMe
