import { extendTheme } from '@chakra-ui/react'

const config = {
	initialColorMode: 'light',
	useSystemColorMode: false,
}
const fonts = {
	heading: 'Open Sans, sans-serif',
	body: 'PT Sans, sans-serif',
}
const components = {
	Heading: {
		baseStyle: { textTransform: 'uppercase' },
		variants: {
			projects: {
				fontFamily: 'Roboto Flex, sans-serif',
				fontSize: '4xl',
			},
			intrests: {
				fontFamily: 'Roboto Flex, sans-serif',
			},
		},
	},
}

const theme = extendTheme({ config, fonts, components })

export default theme
